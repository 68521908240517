const API_ROOT = 'https://maps.googleapis.com/maps/api/geocode/json';

function findPlaceComponent(addressComponents, name) {
  return addressComponents.find((ac) => ac.types.includes(name))?.long_name;
}

function parseResult(resultList) {
  const [place] = resultList;
  const { address_components } = place;

  return {
    street: findPlaceComponent(address_components, 'route'),
    streetNumber: findPlaceComponent(address_components, 'street_number'),
    city: findPlaceComponent(address_components, 'locality'),
    zip: findPlaceComponent(address_components, 'postal_code'),
    country: findPlaceComponent(address_components, 'country'),
  };
}

async function reverseGeocode({ lat, lng }) {
  try {
    const response = await fetch(
      `${API_ROOT}?latlng=${lat},${lng}&key=${process.env.GATSBY_FIREBASE_API_KEY}`
    );
    const result = await response.json();
    return parseResult(result.results);
  } catch (e) {
    //
  }
}

export default {
  reverseGeocode,
};
